// this module will only be available on service worker thread
const getCurrentAppVersion = () => {
  const currentVersion = process.env.REACT_APP_DASHBOARD_CURRENT_VERSION;
  const defaultAppVersion = '0.1.0';

  if (!currentVersion) return defaultAppVersion;

  const indexOfV = currentVersion?.indexOf('v');

  if (indexOfV < 0) return `${defaultAppVersion}rc-${currentVersion}`;
  return currentVersion?.substring(indexOfV + 1);
};

export const APP_VERSION = getCurrentAppVersion();

import { APP_VERSION } from '../version';

const CACHE_VALIDITY_DURATION = {
  default: 1000 * 60 * 60 * 24, // 1 day
  translation: 1000 * 60 * 30 // 30 minutes
};
const DASHBOARD_TRANSLATION_URL = 'https://dashboard-translation-copytext';
const cacheExpiry = {};

console.log(`ServiceWorker app version ${APP_VERSION}`);

const addResourcesToCache = async resources => {
  const cache = await caches.open(APP_VERSION);
  await cache.addAll(resources);
};

const putInCache = async (event, request, response) => {
  const clonedResponse = response.clone();
  event.waitUntil(
    caches.open(APP_VERSION).then(async cache => {
      cacheExpiry[request.url] = new Date().getTime();
      cache.put(request, clonedResponse);
    })
  );
};

const eligibleForCache = (request, response) => {
  const isRequestJs = request.url.endsWith('.js');
  // Mitigation for this fire
  // https://docs.google.com/document/d/12Co8YCsbtawjcAiuwNUGuNEi2f8ds_qLPUwX8JQeiEM/edit
  // if it's not a js file, cache anyway
  if (!isRequestJs) return true;
  const isResponseJs = response.headers.get('content-type')?.includes('application/javascript');
  return isRequestJs && isResponseJs;
};

const isValid = (request, response) => {
  if (!response) return false;
  const requestUrl = request.url;
  const isTranslationType = requestUrl.includes(DASHBOARD_TRANSLATION_URL);
  const cacheValidity = isTranslationType
    ? CACHE_VALIDITY_DURATION.translation
    : CACHE_VALIDITY_DURATION.default;

  const cachedOn = cacheExpiry[requestUrl];
  if (cachedOn) {
    const dateWithValidity = parseFloat(cachedOn) + cacheValidity;
    return dateWithValidity > new Date().getTime();
  }
  return false;
};

const cacheFirst = async (event, { request, preloadResponsePromise }) => {
  // Check from cache
  const responseFromCache = await caches.match(request);
  if (isValid(request, responseFromCache)) {
    return responseFromCache;
  }

  // Check from preloaded response and cache it
  const preloadResponse = await preloadResponsePromise;
  if (isValid(request, preloadResponse)) {
    if (eligibleForCache(request, preloadResponse)) {
      await putInCache(event, request, preloadResponse);
    }
    return preloadResponse;
  }

  // Get from network and cache it
  try {
    const responseFromNetwork = await fetch(request);
    if (eligibleForCache(request, responseFromNetwork)) {
      await putInCache(event, request, responseFromNetwork);
    }
    return responseFromNetwork;
  } catch (error) {
    return new Response('Network error happened', {
      status: 408,
      headers: { 'Content-Type': 'text/plain' }
    });
  }
};

// Enable navigation preload
const enableNavigationPreload = async () => {
  if (self.registration.navigationPreload) {
    await self.registration.navigationPreload.enable();
  }
};

const deleteCache = async key => {
  await caches.delete(key);
};

const deleteOldCaches = async () => {
  const cacheKeepList = [APP_VERSION];
  const keyList = await caches.keys();
  const cachesToDelete = keyList.filter(key => !cacheKeepList.includes(key));
  await Promise.all(cachesToDelete.map(deleteCache));
  console.log('Old caches deleted', cachesToDelete);
};

const shouldCheckCache = request => {
  const requestUrl = request.url;
  if (requestUrl.endsWith('serviceWorker.js')) return false;
  const cachedDestinations = ['image', 'script', 'style', 'font'];
  const isValidDestinations = cachedDestinations.includes(request.destination);
  const isTranslationType = request.url.startsWith(DASHBOARD_TRANSLATION_URL);
  const isHTTPRequest = request.url.startsWith('http');
  const isCacheAvailable = 'caches' in self;
  return (
    isCacheAvailable &&
    isHTTPRequest &&
    (isValidDestinations || isTranslationType)
  );
};

const handleInstall = async _event => {
  console.log(`Installing ServiceWorker version ${APP_VERSION}`);
  _event.waitUntil(
    addResourcesToCache([
      'https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.7.2/animate.min.css',
      'https://fonts.googleapis.com/css?family=Lato:300,400,700|Montserrat|Open+Sans:300,400,600,700|Titillium+Web:400,600,700|Varela+Round|Roboto+Slab:400,700&display=swap',
      'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.12.1/css/all.min.css'
    ])
  );
};

const handleActivation = async _event => {
  console.log(`Activating ServiceWorker version ${APP_VERSION}`);
  _event.waitUntil(enableNavigationPreload());
  _event.waitUntil(deleteOldCaches());
};

/**
 * Set up service worker lifecycle
 */
const start = async () => {
  self.oninstall = async event => {
    await handleInstall(event);
  };

  self.onactivate = async event => {
    await handleActivation(event);
  };

  self.onmessage = async event => {
    const eventType = event.data.type;
    switch (eventType) {
      case 'SKIP_WAITING_COMMAND':
        await self.skipWaiting();
        break;
      default:
        console.log('No message handler');
    }
  };

  self.addEventListener('fetch', event => {
    if (shouldCheckCache(event.request)) {
      event.respondWith(
        cacheFirst(event, {
          request: event.request,
          preloadResponsePromise: event.preloadResponse
        })
      );
    }
  });
};

/**
 * Check if this is a ServiceWorker
 */
const isServiceWorkerAvailable = 'ServiceWorkerGlobalScope' in self;

/**
 * Set up the ServiceWorker
 */
if (isServiceWorkerAvailable) {
  await start();
}

onerror = function (event) {
  console.error(event);
};
